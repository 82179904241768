import { XFile, fetchFile, toXFile } from '@st/util/xfile'
import { PDFBool, PDFDocument, PDFName, StandardFonts } from 'pdf-lib'
import { ReactNode } from 'react'
import { setOutlineWithPDFLib, type PDFOutlineNode } from '../operations/outline'
import { DrawContext, DrawOp, FontResolver, Size, draw } from './draw'
import { embedFonts } from './embed-fonts'
import { renderToPDFStruct } from './render-to-pdf-struct'
import { Font, RenderDebugOpts } from './renderer'
import { PDFPageMode } from './types'
import { sanitizePDFText } from './sanitize-text'

type RenderOpts = {
  debug?: RenderDebugOpts
  defaultFont?: Font
  pageMode?: PDFPageMode
}
export async function renderToPDF(element: ReactNode, opts?: RenderOpts): Promise<PDFDocument> {
  opts = opts ?? {}

  const pageMode = opts.pageMode ?? PDFPageMode.UseOutlines
  const pdfDoc = await PDFDocument.create()

  const fonts = await embedFonts(pdfDoc, {
    Helvetica: StandardFonts.Helvetica,
    HelveticaBold: StandardFonts.HelveticaBold,
    HelveticaItalic: StandardFonts.HelveticaOblique,
    HelveticaBoldItalic: StandardFonts.HelveticaBoldOblique
  })

  const form = pdfDoc.getForm()
  form.updateFieldAppearances(fonts.Helvetica)

  const resolveFont: FontResolver = (font) => {
    let fontName = font.family
    if (font.weight == 'bold') fontName += 'Bold'
    if (font.style == 'italic') fontName += 'Italic'
    return fonts[fontName]
  }

  const structure = renderToPDFStruct(element, {
    debug: opts.debug,
    defaultFont: opts.defaultFont,
    textSize(text, font, fontSize) {
      const resolvedFont = resolveFont(font)
      try {
        return {
          width: resolvedFont.widthOfTextAtSize(text, fontSize),
          height: resolvedFont.heightAtSize(fontSize)
        }
      } catch (e) {
        console.warn(e)
        return { width: 0, height: 0 }
      }
    }
  })

  const defaultFont = opts.defaultFont ?? {
    family: 'Helvetica',
    weight: 'normal',
    style: 'normal'
  }

  const outlineNodes: PDFOutlineNode[] = []
  const drawContext: DrawContext = {
    defaultFont,
    resolveFont,
    sanitizeText: (text) => {
      const font = resolveFont(defaultFont)
      return sanitizePDFText(text, font.getCharacterSet())
    },
    addBookmark: (page, op) => {
      outlineNodes.push({
        title: op.title,
        to: pdfDoc.getPages().indexOf(page),
        fontWeight: op.fontWeight
      })
    },
    updateAppearances: () => {
      const font = resolveFont(defaultFont)
      form.updateFieldAppearances(font)
    }
  }

  for (const pageOp of structure.pages) {
    const page = pdfDoc.addPage([pageOp.size.width, pageOp.size.height])
    draw(page, drawContext, pageOp.drawOp)
  }

  // const finalPdfDoc = await flattenDocument(pdfDoc)

  if (outlineNodes.length > 0) {
    await setOutlineWithPDFLib(pdfDoc, outlineNodes)
  }

  if (pageMode != PDFPageMode.UseNone) {
    pdfDoc.catalog.set(PDFName.of('PageMode'), PDFName.of(pageMode))
  }

  // Acrobat, unlike other PDF readers, only generate appearance streams when it is explicitly told to do so,
  // by setting / NeedsAppearances to true in the AcroForm dictionary.This is usually preserved when you fill out
  // a single form, but when you copy forms, the setting is lost.
  // further reading:
  // - https://github.com/Hopding/pdf-lib/issues/569#issuecomment-1087328416
  // - https://github.com/gettalong/hexapdf/issues/86#issuecomment-544110920
  // - https://stackoverflow.com/questions/38915591/adobe-pdf-forms-text-field-displays-value-only-when-clicked-on-it
  // pdfDoc.getForm().acroForm.dict.set(PDFName.of('NeedAppearances'), PDFBool.True)

  addAutoRemovePDFRuntimeHighlight(pdfDoc)

  return pdfDoc
}

export type Page = {
  index: number
  size: Size
}

export async function drawToPDF(
  file: XFile,
  pageIndexes: number[],
  getPageDrawOps: (page: Page) => DrawOp[],
  opts: RenderOpts
): Promise<XFile> {
  const pdfDoc = await fetchFile(file)
    .then((r) => r.arrayBuffer())
    .then((ab) => PDFDocument.load(ab))

  const fonts = await embedFonts(pdfDoc, {
    Helvetica: StandardFonts.Helvetica,
    HelveticaBold: StandardFonts.HelveticaBold,
    HelveticaItalic: StandardFonts.HelveticaOblique,
    HelveticaBoldItalic: StandardFonts.HelveticaBoldOblique
  })

  const form = pdfDoc.getForm()
  form.updateFieldAppearances(fonts.Helvetica)

  const defaultFont = opts.defaultFont ?? {
    family: 'Helvetica',
    weight: 'normal',
    style: 'normal'
  }

  const resolveFont: FontResolver = (font) => {
    let fontName = font.family
    if (font.weight == 'bold') fontName += 'Bold'
    if (font.style == 'italic') fontName += 'Italic'
    return fonts[fontName]
  }

  const drawContext: DrawContext = {
    defaultFont,
    resolveFont,
    sanitizeText: (text) => {
      const font = resolveFont(defaultFont)
      return filterCharSet(text, font.getCharacterSet())
    },
    addBookmark: (page, op) => {
      // do nothing
    },
    updateAppearances: () => {
      const font = resolveFont(defaultFont)
      form.updateFieldAppearances(font)
    }
  }

  for (const pageIndex of pageIndexes) {
    const page = pdfDoc.getPage(Number(pageIndex))
    const ops = getPageDrawOps({
      index: pageIndex,
      size: { width: page.getWidth(), height: page.getHeight() }
    })
    draw(page, drawContext, { type: 'group', ops })
  }

  const data = await pdfDoc.save()

  return toXFile(new Blob([data], { type: 'application/pdf' }))
}

function filterCharSet(text: string, charSet: number[]) {
  let result = ''
  for (let i = 0; i < text.length; i++) {
    if (charSet.includes(text.charCodeAt(i))) {
      result += text[i]
    } else {
      result += '•'
    }
  }
  return result
}

function addAutoRemovePDFRuntimeHighlight(document: PDFDocument) {
  // const javascriptCode = `
  //   if(app.runtimeHighlight){
  //     var cAlert = app.alert("Your fields highlight is turned 'ON' you won't be able to see fields as we intended.\\nIf you wish to turn it 'OFF' click 'YES' otherwise click 'NO'.\\nTo turn back 'ON' fields highlight go to Preference⇾Forms⇾Show border hover color for fields.",0,2)
  //   }
  //   if(cAlert == 4) app.runtimeHighlight = false;
  // `
  const javascriptCode = `app.runtimeHighlight = false;`
  document.addJavaScript('stanfordtax', javascriptCode)
}
