import { offset, useFloating } from '@floating-ui/react-dom'
import { maskText, MaskType } from '@st/util/mask'
import {
  ComponentType,
  createContext,
  CSSProperties,
  Fragment,
  ReactNode,
  useContext,
  useState
} from 'react'
import { Pad } from './geom'
import * as r from './renderer'

type Props<T> = Omit<T, 'type' | 'children'> & {
  children: ReactNode
}

type OptionalProps<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type DocumentRenderMode = 'pdf' | 'html'

const DocumentRenderModeContext = createContext<DocumentRenderMode>('pdf')

export const DocumentRenderModeProvider = DocumentRenderModeContext.Provider

export function useMode() {
  return useContext(DocumentRenderModeContext)
}

/* eslint-disable react/no-unknown-property */

export function PDFDoc(props: Props<r.Document>) {
  switch (useMode()) {
    case 'pdf':
      return <el tagName="PDFDoc" {...props} />
    case 'html':
      return (
        <div
          className="pdf-doc"
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            boxSizing: 'border-box',
            borderCollapse: 'collapse',
            fontFamily: 'Helvetica',
            zoom: props.zoom ?? 1.0
          }}
        >
          {props.children}
        </div>
      )
  }
}

export function DocumentPage(props: Props<r.Page>) {
  switch (useMode()) {
    case 'pdf':
      return <el tagName="Page" {...props} />
    case 'html':
      return (
        <div
          className="pdf-page"
          style={{
            width: props.width,
            height: props.height,
            display: 'flex',
            flexDirection: 'column',
            background: 'white',
            boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)'
          }}
        >
          {props.children}
        </div>
      )
  }
}

type FlexContext = { totalFlex: number }
const FlexContext = createContext<FlexContext>({ totalFlex: 0 })

export function Flex(props: Props<r.Flex>) {
  const flexContext = calculateFlexContext(props.children)

  switch (useMode()) {
    case 'pdf':
      return (
        <FlexContext.Provider value={flexContext}>
          <el tagName="Flex" {...props} />
        </FlexContext.Provider>
      )
    case 'html':
      return (
        <FlexContext.Provider value={flexContext}>
          <div
            className="pdf-flex"
            style={{
              display: 'flex',
              flexDirection: props.direction,
              alignItems: props.align,
              // a temporary hack
              // for some reason columns add a bunch of space to elements
              // ideally, we want this to be indifferent to rows vs columns
              // 1 1 0 properly expands rows but adds unnecessary heights
              // to elements in columns
              flex: props.direction == 'row' ? '1 1 0' : undefined,
              width: props.direction == 'column' ? '100%' : undefined,
              minWidth: 0
            }}
          >
            {props.children}
          </div>
        </FlexContext.Provider>
      )
  }
}

function calculateFlexContext(children: ReactNode): FlexContext {
  const context: FlexContext = { totalFlex: 0 }
  for (const c of getChildrenPropsOfType(Expand, children)) context.totalFlex += c.flex ?? 1

  return context
}

export function Expand(props: Props<r.Expand>) {
  const context = useContext(FlexContext)

  switch (useMode()) {
    case 'pdf':
      return <el tagName="Expand" {...props} />
    case 'html':
      const flex = props.flex ?? 1
      const calc = `calc(100% * ${flex} / ${context.totalFlex || 1})`

      return (
        <div
          className="pdf-expand"
          style={{
            display: 'flex',
            flex: `0 1 auto`,
            width: calc,
            minWidth: 0
          }}
        >
          {props.children}
        </div>
      )
  }
}

export type BoxProps = OptionalProps<Props<r.Box>, 'children'>
export function Box(props: BoxProps) {
  switch (useMode()) {
    case 'pdf':
      return <el tagName="Box" {...props} />
    case 'html':
      return (
        <div
          className="pdf-box"
          onClick={props.onClick}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: props.vAlign,
            justifyContent: props.hAlign,
            width: props.width == Infinity ? '100%' : props.width,
            height: props.height == Infinity ? '100%' : props.height,
            flex: '0 0 auto', // prevent growing/shrinking
            backgroundColor: props.backgroundColor,
            padding: toHtmlPadding(props.padding),
            outline: toHtmlBorder(props.borderWidth, props.borderColor),
            borderCollapse: 'collapse',
            cursor: props.onClick ? 'pointer' : undefined
          }}
        >
          {props.children}
        </div>
      )
  }
}

export function Text(props: Props<r.Text>) {
  switch (useMode()) {
    case 'pdf':
      return <el tagName="Text" {...props} />
    case 'html':
      return (
        <div
          className="pdf-text"
          style={{
            color: props.color,
            fontFamily: toHtmlFontFamily(props.fontFamily, props.fontWeight),
            fontWeight: props.fontWeight,
            fontStyle: props.fontStyle,
            fontSize: props.fontSize,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            flex: '0 0 auto'
          }}
        >
          {props.children}
        </div>
      )
  }
}

type TextInputProps = Omit<Props<r.TextInput>, 'children'> & {
  onChange?: (value: string) => void
}
export function TextInput(props: TextInputProps) {
  const [focused, setFocused] = useState(false)
  const shouldMask = props.mask == true && !focused

  const displayValue = shouldMask ? maskText(props.value, MaskType.alphanumeric, '●') : props.value

  switch (useMode()) {
    case 'pdf':
      return <el tagName="TextInput" {...props} />
    case 'html':
      const commonProperties: CSSProperties = {
        flex: '1 1 0',
        minWidth: '0',
        fontFamily: toHtmlFontFamily(props.fontFamily),
        fontSize: props.fontSize,
        backgroundColor: props.backgroundColor,
        margin: 0,
        padding: 0,
        outline: toHtmlBorder(props.borderWidth, props.borderColor),
        resize: 'none',
        border: 'none'
      }
      if (props.multiline) {
        return (
          <textarea
            style={{ height: '100%', ...commonProperties }}
            value={displayValue}
            onChange={(e) => props.onChange?.(e.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            autoComplete="off"
          />
        )
      } else {
        return (
          <input
            type="text"
            style={{ height: '18px', ...commonProperties }}
            name={props.name}
            value={displayValue}
            onChange={(e) => props.onChange?.(e.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            autoComplete="off"
          />
        )
      }
  }
}

type HiddenInputProps = Omit<Props<r.TextInput>, 'children'>
export function HiddenInput(props: HiddenInputProps) {
  switch (useMode()) {
    case 'pdf':
      return <el tagName="HiddenInput" {...props} />
    case 'html':
      return <input type="hidden" name={props.name} value={props.value} />
  }
}

export function Radio(props: Omit<Props<r.Radio>, 'children'>) {
  switch (useMode()) {
    case 'pdf':
      return <el tagName="Radio" {...props} />
    case 'html':
      return (
        <input
          type="radio"
          name={props.name}
          checked={props.selected}
          className="accent-black"
          onChange={(e) => props.onChange?.(e.target.checked)}
          style={{
            appearance: 'radio',
            // In html, the accentColor for radio buttons determines the fill of the circle inside
            // not the background color
            // TODO look at: https://stackoverflow.com/questions/74766697/how-can-i-change-the-background-color-of-a-radio-buttons-circle-by-css
            accentColor: /*props.backgroundColor ??*/ '#000000',
            cursor: 'pointer',
            margin: '0',
            width: 12,
            height: 12
          }}
        />
      )
  }
}

export function Select(props: Omit<Props<r.Select>, 'children'>) {
  switch (useMode()) {
    case 'pdf':
      return <el tagName="Select" {...props} />
    case 'html':
      return (
        <select
          name={props.name}
          value={props.value}
          style={{
            appearance: 'none',
            padding: '0 4px',
            margin: '2px 0',
            lineHeight: 1.6,
            width: '100%',
            fontSize: 10
          }}
          onChange={(e) => props.onChange?.(e.target.value)}
        >
          <option
            key={''}
            value={undefined}
            label={props.placeholder ?? 'Select an option'}
            selected={props.value == undefined}
          />
          {props.options.map((option) => (
            <option key={option.value} value={option.value} selected={props.value == option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )
  }
}

export function Checkbox(props: Omit<Props<r.Checkbox>, 'children'>) {
  switch (useMode()) {
    case 'pdf':
      return <el tagName="Checkbox" {...props} />
    case 'html':
      return (
        <input
          type="checkbox"
          name={props.name}
          checked={props.value}
          onChange={(e) => props.onChange?.(e.target.checked)}
          className="accent-black"
          style={{
            appearance: 'checkbox',
            width: 12,
            height: 12,
            accentColor: props.backgroundColor ?? '#000000',
            cursor: 'pointer',
            outline: 0
          }}
        />
      )
  }
}

export function Bookmark(props: Omit<Props<r.Bookmark>, 'children'>) {
  switch (useMode()) {
    case 'pdf':
      return <el tagName="Bookmark" {...props} />
    case 'html':
      return <a style={{ display: 'none' }} id={props.path.replace(/\./g, '-')} />
  }
}

export function Note(props: Omit<Props<r.Note>, 'children'>) {
  const finalProps = { color: '#FFCD46', ...props }

  switch (useMode()) {
    case 'pdf':
      return <el tagName="Note" {...finalProps} />
    case 'html':
      return <HTMLNote author={finalProps.author} body={finalProps.body} color={finalProps.color} />
  }
}

function HTMLNote({ author, body, color }: { author?: string; body: string; color: string }) {
  const { refs, floatingStyles } = useFloating({
    placement: 'top',
    middleware: [offset({ crossAxis: 14, mainAxis: 8 })]
  })
  const [popupVisible, setPopupVisible] = useState(false)

  return (
    <>
      <div
        ref={refs.setReference}
        style={{
          width: 0,
          height: 20,
          // hack to offset the marginRight which impacts layout and causes overflow
          // this effectively makes the element 0 width and invisible to layout calculations
          paddingLeft: 8,
          marginRight: -8,
          overflow: 'visible'
        }}
        onMouseEnter={() => setPopupVisible(true)}
        onMouseLeave={() => setPopupVisible(false)}
      >
        <NoteIcon color={color} />
      </div>
      {popupVisible && (
        <div
          ref={refs.setFloating}
          style={{
            zIndex: 10000,
            backgroundColor: '#FFFAED',
            overflow: 'hidden',
            color: '#333',
            border: '1px solid #BFBFBF',
            boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.2)',
            fontFamily: 'Arial, sans-serif',
            fontSize: '10px', // Font size
            padding: '8px 14px',
            borderRadius: '2px',
            maxWidth: 150,
            ...floatingStyles
          }}
        >
          {author ? (
            <header
              style={{
                fontWeight: 'bold',
                fontSize: 10,
                marginBottom: 6,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {author}
            </header>
          ) : null}
          <main style={{ whiteSpace: 'pre-wrap' }}>{body}</main>
        </div>
      )}
    </>
  )

  function NoteIcon({ color }: { color: string }) {
    return (
      <div style={{ width: 20, height: 20 }}>
        <svg
          style={{ color: color }}
          width="20"
          height="20"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="38" height="38" fill="currentColor" />
          <path d="M31 7H7V26H14.5V32L21 26H31V7Z" fill="white" />
          <rect x="10" y="14" width="17" height="1.5" fill="currentColor" />
          <rect x="10" y="18" width="14" height="1.5" fill="currentColor" />
        </svg>
      </div>
    )
  }
}

// eslint-enable react/no-unknown-property

function toHtmlBorder(
  borderWidth: number | undefined,
  borderColor: string | undefined
): string | undefined {
  if (!borderColor) return 'none'
  if (borderWidth == 0) return 'none'
  return `${borderWidth}px solid ${borderColor}`
}

function toHtmlFontFamily(
  fontFamily: string | undefined,
  fontWeight?: r.FontWeight | undefined
): string | undefined {
  if (!fontFamily) return 'inherit'
  return fontFamily + (fontWeight == 'bold' ? 'Bold' : '')
}

function toHtmlPadding(padding: Pad | undefined): string | undefined {
  if (!padding) return undefined

  return [padding.top, padding.right, padding.bottom, padding.left].map((n) => `${n}px`).join(' ')
}

// utils

function getChildrenPropsOfType<Props>(
  Component: ComponentType<Props>,
  children: ReactNode
): Props[] {
  return flattenChildren(children)
    .filter((c) => {
      return (c as any).type == Component
    })
    .map<Props>((c) => (c as any).props)
}

function flattenChildren(children: ReactNode): ReactNode[] {
  if (!children) return []
  if (!Array.isArray(children)) return [children]

  return children.flatMap(function (c) {
    // unwrap fragments
    if (c?.type == Fragment) return flattenChildren(c.props.children)
    return flattenChildren(c)
  })
}
