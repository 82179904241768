import { assetURL } from '@features/app-hooks'
import { TaxAppId } from '@st/tax-folder'
import { DocumentDropZone, DropHere, DropProgress, ProgressStepIndicator, Wizard } from '@st/theme'
import { Button } from '@st/theme'
import { ReactNode, useState } from 'react'
import { match } from 'ts-pattern'

export type UploadState =
  | { status: 'idle' }
  | { status: 'preparingUpload' }
  | {
      status: 'uploading'
      bytesTransferred: number
      totalBytes: number
    }

type Props = {
  taxAppId: TaxAppId
  onClose: () => void
  onBack: () => void
  onDrop: (dataTransfer: DataTransfer) => void
  uploadState: UploadState
}

export function UploadFolderImportWizard({
  taxAppId,
  onClose,
  onBack,
  onDrop,
  uploadState
}: Props) {
  switch (taxAppId) {
    case 'axcess':
      return (
        <AxcessImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadState={uploadState}
        />
      )
    case 'drake':
      return (
        <DrakeImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadState={uploadState}
        />
      )
    case 'lacerte':
      return (
        <LacerteImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadState={uploadState}
        />
      )
    case 'proseries':
      return (
        <ProseriesImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadState={uploadState}
        />
      )
    case 'prosystem':
      return (
        <ProsystemImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadState={uploadState}
        />
      )
    case 'ultratax':
      return (
        <UltrataxImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadState={uploadState}
        />
      )
    case 'proconnect':
      return (
        <ProconnectImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadState={uploadState}
        />
      )
    case 'csv':
      return (
        <CSVImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadState={uploadState}
        />
      )
    default:
      return <div>Not implemented</div>
  }
}

function VisualStep({
  title,
  subtitle,
  image,
  stepNumber,
  stepCount
}: {
  title?: string
  subtitle?: string
  image?: string
  caption?: string
  stepNumber: number
  stepCount: number
}) {
  return (
    <Wizard.Main
      variant="wide"
      progress={
        stepCount > 1 && <ProgressStepIndicator stepNumber={stepNumber} stepCount={stepCount} />
      }
      title={title}
      subtitle={subtitle}
    >
      {image && (
        <img className="w-[1000px] object-contain" src={assetURL(`instructions/${image}`)} />
      )}
    </Wizard.Main>
  )
}

function UploadStep({
  title,
  subtitle,
  uploadTitle,
  uploadSubtitle,
  stepNumber,
  stepCount,
  onDrop,
  uploadState,
  children
}: {
  title?: string
  subtitle?: string
  uploadTitle: ReactNode
  uploadSubtitle: string
  stepNumber: number
  stepCount: number
  onDrop: (dataTransfer: DataTransfer) => void
  uploadState: UploadState
  children?: ReactNode
}) {
  return (
    <Wizard.Main
      variant="wide"
      progress={
        stepCount > 1 && <ProgressStepIndicator stepNumber={stepNumber} stepCount={stepCount} />
      }
      title={title}
      subtitle={subtitle}
    >
      {children}

      <DocumentDropZone className="w-[600px]" onDrop={onDrop}>
        {match(uploadState)
          .with({ status: 'idle' }, () => (
            <DropHere caption={uploadSubtitle}>{uploadTitle}</DropHere>
          ))
          .with({ status: 'preparingUpload' }, () => (
            <DropProgress progress={0} caption="Preparing upload..." />
          ))
          .with({ status: 'uploading' }, (uploadState) => (
            <DropProgress
              progress={uploadState.bytesTransferred / uploadState.totalBytes}
              caption="Uploading..."
            />
          ))
          .run()}
      </DocumentDropZone>
    </Wizard.Main>
  )
}

// Axcess
function AxcessImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadState
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 6

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={6}
            title="Add clients from Axcess"
            image="axcess/2024/axcess-instructions-1.png"
            subtitle={`Open up Axcess 2023 (the prior year tax software) and select the clients you'd like to add to StanfordTax.`}
          />
        ))
        .with(2, () => (
          <VisualStep
            stepNumber={2}
            stepCount={6}
            title="Add clients from Axcess"
            image="axcess/2024/axcess-instructions-2.png"
            subtitle={`Click “Utilities” and select “Transfer to Prosystem fx.” This does not require you to have ProSystem fx as you will not actually be transferring any data to ProSystem fx. (note: if the button is disabled, it is because you must have the “Return transfer” functional right permission assigned to use the Transfer to ProSystem fx feature.)`}
          />
        ))
        .with(3, () => (
          <VisualStep
            stepNumber={3}
            stepCount={6}
            title="Add clients from Axcess"
            image="axcess/2024/axcess-instructions-3.png"
            subtitle={`Enter an “Office group name,” select an “Export file location” - and then click “Transfer” (note: we recommend creating a folder on your Desktop called StanfordTax and exporting to that folder)`}
          />
        ))
        .with(4, () => (
          <VisualStep
            stepNumber={4}
            stepCount={6}
            title="Add clients from Axcess"
            image="axcess/2024/axcess-instructions-4.png"
            subtitle={`Wait for the process to complete (note: Axcess is preparing an export file at this step, and is not actually transferring returns to Prosystem fx)`}
          />
        ))
        .with(5, () => (
          <VisualStep
            stepNumber={5}
            stepCount={6}
            title="Add clients from Axcess"
            image="axcess/2024/axcess-instructions-5.png"
            subtitle={`Once the process is complete you will see the name of the export file (all clients will be exported into the same .dat file). This is the file you'll be uploading to StanfordTax, so make note of the name.`}
          />
        ))
        .with(6, () => (
          <UploadStep
            stepNumber={6}
            stepCount={6}
            title="Add clients from Axcess"
            subtitle={`Find the export file on your computer, and upload it below.`}
            uploadTitle={
              <>
                Drop your backup here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadState={uploadState}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

// Drake
function DrakeImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadState
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 5

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={5}
            title="Add clients from Drake"
            image="drake/drake-instructions-1.png"
            subtitle={`Open up Drake 2023 (the prior year tax software) and go to Tools > File Maintenance > Backup.`}
          />
        ))
        .with(2, () => (
          <VisualStep
            stepNumber={2}
            stepCount={5}
            title="Add clients from Drake"
            image="drake/drake-instructions-2.png"
            subtitle={`Select where you'd like the backup to be stored on your computer, select "Selective Backup", and click "Backup."`}
          />
        ))
        .with(3, () => (
          <VisualStep
            stepNumber={3}
            stepCount={5}
            title="Add clients from Drake"
            image="drake/drake-instructions-3.png"
            subtitle={`Select "Customize Backup" at the top and "Tax Returns" on the left side panel. Then select the clients you'd like to include in the backup, and click "Backup."`}
          />
        ))
        .with(4, () => (
          <VisualStep
            stepNumber={4}
            stepCount={5}
            title="Add clients from Drake"
            image="drake/drake-instructions-4.png"
            subtitle={`After you click "Backup", Drake will generate your backup.`}
          />
        ))
        .with(5, () => (
          <UploadStep
            stepNumber={5}
            stepCount={5}
            title="Add clients from Drake"
            subtitle={`Locate the backup file on your computer (the file name will look something like D22_DB_05-26-23_15-51.7z) and upload it below.`}
            uploadTitle={
              <>
                Drop your backup here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadState={uploadState}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

// Lacerte
function LacerteImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadState
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 3

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={3}
            title="Add clients from Lacerte"
            image="lacerte/lacerte-instructions-1.png"
            subtitle={`Open up Lacerte 2023 (the prior year tax software) and select the clients you'd like to add to StanfordTax. Then select 'Backup' within the Client menu.`}
          />
        ))
        .with(2, () => (
          <VisualStep
            stepNumber={2}
            stepCount={3}
            title="Add clients from Lacerte"
            image="lacerte/lacerte-instructions-2.png"
            subtitle={`Select where you'd like the backup to be stored, and click "Ok"`}
          />
        ))
        .with(3, () => (
          <UploadStep
            stepNumber={3}
            stepCount={3}
            title="Add clients from Lacerte"
            subtitle={`Drag and drop the entire DETAIL folder.`}
            uploadTitle={
              <>
                Drop your folder here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadState={uploadState}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

// Proseries
function ProseriesImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadState
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 4

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={4}
            title="Add clients from ProSeries"
            image="proseries/proseries-instructions-1.png"
            subtitle={`Open up Proseries 2023 (the prior year tax software) and go to "HomeBase." Select the clients you'd like to add to StanfordTax. You can select multiple clients at once by holding the shift key.`}
          />
        ))
        .with(2, () => (
          <VisualStep
            stepNumber={2}
            stepCount={4}
            title="Add clients from ProSeries"
            image="proseries/proseries-instructions-2.png"
            subtitle={`Go to File > Client File Maintenance > Copy/Backup.`}
          />
        ))
        .with(3, () => (
          <VisualStep
            stepNumber={3}
            stepCount={4}
            title="Add clients from ProSeries"
            image="proseries/proseries-instructions-3.png"
            subtitle={`Select where you'd like the backup to be stored on your computer, and click "Backup Clients."`}
          />
        ))
        .with(4, () => (
          <UploadStep
            stepNumber={4}
            stepCount={4}
            title="Add clients from ProSeries"
            subtitle={`Locate the folder where you chose to save the backup. The folder should contain a .23i for each client. Drag & drop the entire folder, with all the .23i files, below. If you would like to import a single client, drag & drop just that client's .23i file.`}
            uploadTitle={
              <>
                Drop your folder here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadState={uploadState}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

// Prosystem
function ProsystemImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadState
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 8

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-1.png"
            subtitle={`In Office Manager, select "Backup Client Data"`}
          />
        ))
        .with(2, () => (
          <VisualStep
            stepNumber={2}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-2.png"
            subtitle={`Select "All Clients" and click "OK" (note: you'll be able to select specific clients in the next step).`}
          />
        ))
        .with(3, () => (
          <VisualStep
            stepNumber={3}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-3.png"
            subtitle={`Select the clients you'd like to add to StanfordTax, and click "Add." You can also click "Add All" to add all clients.`}
          />
        ))
        .with(4, () => (
          <VisualStep
            stepNumber={4}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-4.png"
            subtitle={`Click "Select Drive"`}
          />
        ))
        .with(5, () => (
          <VisualStep
            stepNumber={5}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-5.png"
            subtitle={`Select where you'd like the backup file to be stored on your computer, and then click "OK" (note: you might have to double-click the directory name before clicking "OK")`}
          />
        ))
        .with(6, () => (
          <VisualStep
            stepNumber={6}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-6.png"
            subtitle={`If you have previous backups stored on your computer for the same clients, you'll see a popup like the one below. Select "Overwrite All Files."`}
          />
        ))
        .with(7, () => (
          <VisualStep
            stepNumber={7}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-7.png"
            subtitle={`In addition to the backup file, Prosystem generates a PDF report of the backup. Choose any name you'd like for the report, and click "Save."`}
          />
        ))
        .with(8, () => (
          <UploadStep
            stepNumber={8}
            stepCount={8}
            title="Add clients from ProSystem fx"
            subtitle={`Locate the folder where you chose to save the backup. The folder should contain a .ZIP for each client, and a CLNTBKUP.001 file. Drag & drop the entire folder below.`}
            uploadTitle={
              <>
                Drop your folder here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadState={uploadState}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

// Ultratax
function UltrataxImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadState
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 7

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={7}
            title="Add clients from UltraTax"
            image="ultratax/ultratax-instructions-1.png"
            subtitle={`Open up UltraTax 2023 (the prior year tax software) and select "Backup" within the File menu.`}
          />
        ))
        .with(2, () => (
          <VisualStep
            stepNumber={2}
            stepCount={7}
            title="Add clients from UltraTax"
            image="ultratax/ultratax-instructions-2.png"
            subtitle={`Select the clients you'd like to add to StanfordTax, and click "Select." You can also click "Select All" if you'd like to add all clients.`}
          />
        ))
        .with(3, () => (
          <VisualStep
            stepNumber={3}
            stepCount={7}
            title="Add clients from UltraTax"
            image="ultratax/ultratax-instructions-3.png"
            subtitle={`Select where you'd like the backup to be stored.`}
          />
        ))
        .with(4, () => (
          <VisualStep
            stepNumber={4}
            stepCount={7}
            title="Add clients from UltraTax"
            image="ultratax/ultratax-instructions-4.png"
            subtitle={`Uncheck the "Encrypt client backup" checkbox.`}
          />
        ))
        .with(5, () => (
          <VisualStep
            stepNumber={5}
            stepCount={7}
            title="Add clients from UltraTax"
            image="ultratax/ultratax-instructions-5.png"
            subtitle={`Click "Backup"`}
          />
        ))
        .with(6, () => (
          <VisualStep
            stepNumber={6}
            stepCount={7}
            title="Add clients from UltraTax"
            image="ultratax/ultratax-instructions-6.png"
            subtitle={`Click "Done"`}
          />
        ))
        .with(7, () => (
          <UploadStep
            stepNumber={7}
            stepCount={7}
            title="Add clients from UltraTax"
            subtitle={`Locate the folder where you chose to save the backup (it will contain a CltBKLst.WT3 file and client folders). Drag & drop the ENTIRE folder below.`}
            uploadTitle={
              <>
                Drop your folder here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadState={uploadState}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

// Proconnect
function ProconnectImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadState
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 2

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={2}
            title="Add clients from ProConnect"
            image="proconnect/pro-connect-call-icon.png"
            subtitle={`Our integration with ProConnect uses what's called a “Lacerte Data File.” To get this file, contact ProConnect support by requesting a phone callback through their help portal (https://ito.intuit.com/app/protax/conversation) or by calling them directly at 1-800-200-7599. They will prepare the Lacerte file for you and send you a link to download the file. Note: they will tell you that it takes 4-6 weeks to prepare the file, but most StanfordTax users receive it within one week.`}
          />
        ))
        .with(2, () => (
          <UploadStep
            stepNumber={2}
            stepCount={2}
            title="Add clients from ProConnect"
            subtitle={`Upload the Lacerte Data File below`}
            uploadTitle={
              <>
                Drop your file here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadState={uploadState}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

function CSVImportInstructions({ onClose, onBack, onDrop, uploadState }: Omit<Props, 'taxAppId'>) {
  function onPrev() {
    onBack()
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
        </Wizard.BottomNav>
      }
    >
      <UploadStep
        stepNumber={1}
        stepCount={1}
        title="Add clients from CSV"
        subtitle={`Download the sample CSV templates and fill them out with your clients' information. Then upload them below.`}
        uploadTitle={
          <>
            Drop your .csv files here <a className="text-blue-500">or browse</a>
          </>
        }
        uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
        uploadState={uploadState}
        onDrop={onDrop}
      >
        <div className="mb-4 flex flex-row gap-2">
          <Button variant="default" href="/samples/stanfordtax-sample-individual.csv">
            Download Individual CSV Template
          </Button>
          <Button variant="default" href="/samples/stanfordtax-sample-corporate.csv">
            Download Corporate CSV Template
          </Button>
        </div>
      </UploadStep>
    </Wizard.Scaffold>
  )
}
