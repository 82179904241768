import { FileStorageClient, UploadOpts } from '@st/folder'
import { STSDK } from '@st/sdk'

export class S3StorageClient implements FileStorageClient {
  constructor(private sdk: STSDK) {}

  upload = async (file: File, opts: UploadOpts) => {
    const genResponse = await this.sdk.send({
      type: 'storage/generatePresignedUploadURL',
      filename: file.name,
      filepath: opts.destinationPath
    })

    await uploadToPresignedUrl(file, {
      presignedUrl: genResponse.uploadUrl,
      headers: genResponse.uploadHeaders as Record<string, string>,
      onProgress: opts.onProgress
    })
  }

  fetch = async (filepath: string) => {
    const presignedURL = await this.resolveURL(filepath)
    return fetch(presignedURL)
  }

  resolveURL = async (filepath: string) => {
    if (!filepath.startsWith('storage://')) {
      return filepath
    }

    const authResp = await this.sdk.send({
      type: 'storage/generatePresignedGetURL',
      filepath: dropStorageProtocol(filepath)
    })

    return authResp.url
  }

  resolvePublicURL = (filepath: string) => {
    // already fully qualified name
    if (filepath.startsWith('https://') || filepath.startsWith('http://')) {
      return filepath
    }
    return process.env.NEXT_PUBLIC_STORAGE_ENDPOINT! + `/${dropStorageProtocol(filepath)}`
  }

  delete = async (filepath: string) => {
    // do nothing for now
  }
}

function dropStorageProtocol(filepath: string) {
  return filepath.startsWith('storage://') ? filepath.substring('storage://'.length) : filepath
}

type ProgressEvent = { bytesTransferred: number; totalBytes: number }
type UploadsOpts = {
  presignedUrl: string
  headers: Record<string, string>
  onProgress?: (e: ProgressEvent) => void
}
export async function uploadToPresignedUrl(file: File, opts: UploadsOpts) {
  var xhr = new XMLHttpRequest()
  xhr.open('PUT', opts.presignedUrl, true)

  for (const [key, value] of Object.entries(opts.headers)) {
    xhr.setRequestHeader(key, value)
  }

  return new Promise<void>((resolve, reject) => {
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        opts.onProgress?.({
          bytesTransferred: event.loaded,
          totalBytes: event.total
        })
      }
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        opts.onProgress?.({
          bytesTransferred: file.size,
          totalBytes: file.size
        })
        resolve()
      } else {
        reject(new Error(`Upload failed with status ${xhr.status}`))
      }
    }

    xhr.onerror = () => {
      reject(new Error('Upload failed due to a network error'))
    }

    xhr.onabort = () => {
      reject(new Error('Upload was aborted'))
    }

    xhr.send(file)
  })
}
