import { NormalizedRect } from '@st/util/geom'
import { OptionConfig } from '../ui-config'

export type TagOption = {
  key: string
  background: string
  color: string
  label: string
  readOnly?: boolean
}

/**
 * The UI collapses all the possible roles into 2: organization_member and client
 * This is because there are only 2 variations of the UX based on one of these
 */
export type FolderUIRole =
  /**
   * A member of the organization (an accountant)
   */
  | 'organization_member'
  /**
   * The client who is meant to submit their info (in the database it can be a client or collaborator)
   */
  | 'client'

export type BaseDocAnnotation = {
  id: string
  author?: string
  bounds: NormalizedRect
  page: number
  time: string
  title?: string
}

export type DocCommentAnnotation = BaseDocAnnotation & {
  type: 'comment'
  body: string
}

export type DocTextAnnotation = BaseDocAnnotation & {
  type: 'text'
  body: string
  color: string
  font: { size: number }
}

export type DocRectAnnotation = BaseDocAnnotation & {
  type: 'rect'
  border: {
    color: string
    width: number
  }
}

export type DocFieldAnnotation = BaseDocAnnotation & {
  type: 'field'
  border: {
    color: string
    width: number
  }
  body?: string
  color?: string
}

export type DocStampAnnotation = BaseDocAnnotation & {
  type: 'stamp'
  color: string
  body?: string
}

export type DocCalculationAnnotation = BaseDocAnnotation & {
  type: 'calculation'
  title?: string
  lineItems?: Record<string, DocCalculationLineItem>
  total: number
}
export type DocCalculationLineItem = {
  id: string
  description?: string
  amount: number
  order: string
}

// Optionally, you can redefine the original DocAnnotation type as a union of the new distinct types
export type DocAnnotation =
  | DocCommentAnnotation
  | DocTextAnnotation
  | DocRectAnnotation
  | DocFieldAnnotation
  | DocStampAnnotation
  | DocCalculationAnnotation

export type DocField = {
  /**
   * An optional human readable label to show on the frontend
   */
  label?: string
  /**
   * Page number (starting from 0) on where it is on the page
   */
  page: number
  /**
   * The input-key (identifier) that this field refers to for example "employer.name"
   * Should be compatible with the doc input keys used within the rest of the schema
   */
  key: string
  /**
   * The value of the field
   */
  value: string | null
  /**
   * The confidence of the value (a number between 0 and 1 where 1 means 100% confidence)
   */
  confidence?: number
  /**
   * The rectangle where the data is on the page.
   * Normalized meaning x/y/width/height are specified as percentages rather than pixels
   */
  rect: NormalizedRect | null
  present: boolean
}

export const ENTITY_OPTIONS: OptionConfig[] = [
  { key: 'individual', label: 'Individual' },
  { key: 'partnership', label: 'Partnership' },
  { key: 'corporation', label: 'Corporation' },
  { key: 's_corporation', label: 'S Corporation' },
  { key: 'trust', label: 'Trust' },
  { key: 'estate', label: 'Estate' },
  { key: 'exempt_organization', label: 'Exempt Organization' },
  { key: 'ira', label: 'IRA' },
  { key: 'llc', label: 'LLC' },
  { key: 'disregarded_entity', label: 'Disregarded Entity' },
  { key: 'other', label: 'Other' }
]
