import {
  selSuggestedCategoryIds,
  stFolderModule
} from '@features/st-folder-viewer/st-folder-module'
import {
  categorySelectorCategoryGroups,
  stQuestionnaireModule
} from '@features/st-questionnaire/st-questionnaire-module'
import { WizardCategoryConfig } from '@st/folder/ui-config'
import { useReadWriteContext } from '@st/pdf'
import { useProcessState } from '@st/redux'
import { MultiSelectOption } from '@theme'

type CategoriesSelectorProps = { sectionId: string }
export function CategoriesSelector({ sectionId }: CategoriesSelectorProps) {
  const categoryGroups = useProcessState(stQuestionnaireModule, (s) =>
    categorySelectorCategoryGroups(s, sectionId)
  )

  const suggestedCategoryIds = useProcessState(stFolderModule, selSuggestedCategoryIds)

  return (
    <div className="categories flex flex-col gap-10">
      {categoryGroups.map((section) => {
        return (
          <div key={section.heading}>
            <h2 className="font-lighter bg-white text-lg">{section.heading}</h2>
            <div style={{ height: '15px' }} />
            <div className="flex flex-col gap-5">
              {section.items.map((c) => {
                return (
                  <CategoryToggleButton
                    key={c.id}
                    {...c}
                    noteTip={suggestedCategoryIds.includes(c.id) ? 'Suggested' : undefined}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

function CategoryToggleButton({
  isSelectedUserInputKey,
  name,
  caption,
  tip,
  noteTip
}: WizardCategoryConfig & { noteTip?: string }) {
  const { useRead, useWrite } = useReadWriteContext()
  const value = useRead(isSelectedUserInputKey!)
  const write = useWrite()

  const selected = value === true

  return (
    <MultiSelectOption
      caption={caption ?? undefined}
      tip={tip}
      selected={selected}
      noteTip={noteTip}
      onClick={() => write({ [isSelectedUserInputKey!]: !selected })}
    >
      {name}
    </MultiSelectOption>
  )
}
