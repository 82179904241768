import { AnnotationMessage, DrawTool, Page } from '@features/st-annotation-layer'
import { DocAnnotation, DocField, DocFieldAnnotation } from '@st/folder'
import { DocumentType } from '@st/folder/ui-config'
import { defineModule } from '@st/redux'
import { LinearTransform, NormalizedRect } from '@st/util/geom'
import { isNotEmpty } from '@st/util/json-value'
import { create } from 'mutative'

import { COMMENT_TOOL, CommentTool } from './tools/comment-tool'
import { FIELD_TOOL, FieldTool } from './tools/field-tool'
import { RECT_TOOL, RectTool } from './tools/rect-tool'
import { SELECT_TOOL, SelectTool } from './tools/select-tool'
import { TEXT_TOOL, TextTool } from './tools/text-tool'

export type AnnotType = 'comment' | 'field'

export const TOOLS: DrawTool<any, any>[] = [
  SELECT_TOOL,
  COMMENT_TOOL,
  TEXT_TOOL,
  RECT_TOOL,
  FIELD_TOOL
]

export type DocTool = SelectTool | CommentTool | TextTool | RectTool | FieldTool

export type DocField = {
  /**
   * An optional human readable label to show on the frontend
   */
  label?: string
  /**
   * Page number (starting from 0) on where it is on the page
   */
  page: number
  /**
   * The input-key (identifier) that this field refers to for example "employer.name"
   * Should be compatible with the doc input keys used within the rest of the schema
   */
  key: string
  /**
   * The value of the field
   */
  value: string | null
  /**
   * The confidence of the value (a number between 0 and 1 where 1 means 100% confidence)
   */
  confidence?: number
  /**
   * The rectangle where the data is on the page.
   * Normalized meaning x/y/width/height are specified as percentages rather than pixels
   */
  rect: NormalizedRect | null
  present: boolean
}

type State = {
  annotType: AnnotType | undefined
  pages: Page[]
  annots: DocAnnotation[]
  readonlyAnnots: DocAnnotation[]
}

type RecognizeDocResult = {
  docTypeId: string | null
  fields: DocField[]
}

type Message =
  | {
      type: 'setAnnotType'
      annotType: AnnotType | undefined
    }
  | { type: 'pagesLoaded'; pages: Page[] }
  | { type: 'annotsChanged'; annots: DocAnnotation[] }
  | { type: 'docRecognized'; result: RecognizeDocResult; docType: DocumentType }

type Send = {
  annotationLayer: AnnotationMessage[]
}

export const docViewerModule = defineModule<
  State,
  Message,
  {},
  { annotationLayer: AnnotationMessage[] }
>({
  name: 'docViewer',
  init: () => {
    return { annotType: undefined, pages: [], annots: [], readonlyAnnots: [] }
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'setAnnotType':
        return { ...state, annotType: message.annotType }
      case 'pagesLoaded':
        return maybeBroadcastAnnots({ ...state, pages: message.pages })
      case 'annotsChanged':
        return maybeBroadcastAnnots({ ...state, annots: message.annots })
      case 'docRecognized':
        return maybeBroadcastAnnots(
          create(state, (s) => {
            s.readonlyAnnots = buildFieldAnnotations(message.result.fields)
            console.log('readonlyAnnots', s.readonlyAnnots)
            const preferredAnnotType = getPreferredDocAnnotationType([
              ...s.annots,
              ...s.readonlyAnnots
            ])
            if (!s.annotType && preferredAnnotType) {
              s.annotType = preferredAnnotType
            }
          })
        )
    }
  }
})

export function getPreferredDocAnnotationType(
  annots: DocAnnotation[]
): 'comment' | 'field' | undefined {
  const hasComments = annots.some((a) => a.type == 'comment')
  const hasFields = annots.some((a) => a.type == 'field')
  if (hasComments) {
    return 'comment'
  } else if (hasFields) {
    return 'field'
  }
  return undefined
}

function maybeBroadcastAnnots(state: State): State | [State, Send] {
  // if (state.pages.length > 0) {
  //   return [
  //     state,
  //     {
  //       annotationLayer: [
  //         {
  //           type: 'load',
  //           annots: denormalizeAnnots([...state.annots, ...state.readonlyAnnots], state.pages),
  //           pages: state.pages
  //         }
  //       ]
  //     }
  //   ]
  // }

  return state
}

function buildFieldAnnotations(fields: DocField[]): DocFieldAnnotation[] {
  return fields
    .map((field) => {
      if (field.present === false) {
        return undefined
      }
      if (!field.rect) {
        return undefined
      }

      return {
        type: 'field',
        id: field.key,
        time: '2000-01-01T00:00:00Z',
        page: field.page,
        bounds: field.rect!,
        color: '#e6f5fa',
        border: { color: '#186a8a', width: 2 },
        title: field.label,
        body: field.value!
      } satisfies DocFieldAnnotation
    })
    .filter(isNotEmpty)
}

function denormalizeAnnots(annots: DocAnnotation[], pages: Page[]) {
  return annots.map((annot) => {
    const page = pages[annot.page]
    const transform = LinearTransform.denormalize(page.size)
    return create(annot, (a) => {
      a.bounds = LinearTransform.apply(annot.bounds, transform)
    })
  })
}
