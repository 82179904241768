import { useAppDeps } from '@features/app-deps-provider'
import { platformModule } from '@features/st-pdf-viewer/platform-module'
import {
  Box,
  Checkbox,
  Column,
  Expand,
  FORM_BORDER_GREY,
  Pad,
  PDFElementDocViewer,
  PDFFormDocument,
  PDFFormPage,
  Row,
  Space,
  Text
} from '@st/pdf'
import { DragHandlersProps, DragState, useDraggable } from '@st/react-util/use-draggable'
import { useProcess, useProcessState } from '@st/redux'
import { STChecklistItem, STDocument, STMissingReason } from '@st/sdk'
import {
  Button,
  Checklist,
  ChecklistVisual,
  CTACard,
  Dialog,
  DialogButtons,
  LinkIcon,
  Modal,
  PlusIcon,
  Select,
  TextInput
} from '@st/theme'
import { PencilSimpleIcon, TrashIcon } from '@st/theme/src/icons/12x12'
import { paginate, PaginatedPage } from '@st/util/array'
import { ReactNode, useState } from 'react'
import { match } from 'ts-pattern'
import { ManageChecklistItemCompletion } from './st-checklist-completion-dialog'
import {
  CHECKLIST_ITEMS_PER_PAGE,
  formatChecklistItem,
  formatFolderEntityName,
  selChecklistItems,
  selDocumentTypeOptions,
  selMissingReasonsById,
  stFolderModule
} from './st-folder-module'
import { STRequestItemsDialog } from './st-request-items-dialog'

type ChecklistItemAction =
  | { type: 'manageCompletion'; checklistItem: STChecklistItem }
  | { type: 'delete'; checklistItem: STChecklistItem }
  | { type: 'edit'; checklistItem: STChecklistItem }
  | { type: 'selectDocument'; checklistItem: STChecklistItem; document: STDocument }
  | { type: 'requestItems' }
  | {
      type: 'move'
      checklistItem: STChecklistItem
      referenceChecklistItem: STChecklistItem
      relation: 'before' | 'after'
    }

type Props = {}
export function STFormationChecklistViewer({}: Props) {
  const stFolder = useProcess(stFolderModule)

  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)
  const entityName = useProcessState(stFolderModule, (s) =>
    formatFolderEntityName(s.folderState!.folder.entities)
  )
  const year = useProcessState(stFolderModule, (s) => s.folderState!.folder.year)
  const type = useProcessState(stFolderModule, (s) => s.folderState!.folder.type)
  const checklistItemPages = useProcessState(stFolderModule, (s) =>
    paginate(selChecklistItems(s), CHECKLIST_ITEMS_PER_PAGE)
  )
  const missingReasonsById = useProcessState(stFolderModule, (s) =>
    selMissingReasonsById(s.folderState!)
  )

  const documents = useProcessState(stFolderModule, (s) => s.folderState!.documents)

  const [action, setAction] = useState<ChecklistItemAction | undefined>()

  function onAction(action: ChecklistItemAction) {
    switch (action.type) {
      case 'requestItems':
      case 'manageCompletion':
      case 'delete':
        setAction(action)
        break
      case 'move':
        stFolder.send({
          type: 'request',
          request: {
            type: 'folders/moveChecklistItem',
            folderId: folderId,
            checklistItemId: action.checklistItem.id,
            relation: action.relation,
            referenceChecklistItemId: action.referenceChecklistItem.id
          }
        })
        break
      case 'selectDocument':
        stFolder.send({ type: 'docSelected', docId: action.document.id })
        break
    }
  }

  const { dragState, handlers } = useDraggable<STChecklistItem>({
    onDrop: (e) => {
      onAction({
        type: 'move',
        checklistItem: e.item,
        referenceChecklistItem: e.target,
        relation: e.relation
      })
    }
  })

  return (
    <>
      <PDFElementDocViewer mode="html">
        <PDFFormDocument>
          {checklistItemPages.map((page) => {
            return (
              <PDFFormPage
                key={page.number}
                width={1000}
                height={1410}
                padding={{ top: 60, bottom: 60, left: 80, right: 80 }}
              >
                <STFormationChecklistHTML
                  entityName={entityName}
                  year={year}
                  type={type}
                  documents={documents}
                  page={page}
                  missingReasonsById={missingReasonsById}
                  onChecklistItemAction={onAction}
                  dragHandlers={handlers}
                  dragState={dragState}
                />
              </PDFFormPage>
            )
          })}
        </PDFFormDocument>
      </PDFElementDocViewer>
      {match(action)
        .with({ type: 'requestItems' }, () => (
          <Modal isOpen={true}>
            <STRequestItemsDialog folderId={folderId} onClose={() => setAction(undefined)} />
          </Modal>
        ))
        .with({ type: 'manageCompletion' }, ({ checklistItem }) => (
          <Modal isOpen={true}>
            <ManageChecklistItemCompletion
              folderId={folderId}
              checklistItem={checklistItem}
              onClose={() => setAction(undefined)}
            />
          </Modal>
        ))
        .with({ type: 'delete' }, ({ checklistItem }) => (
          <Modal isOpen={true}>
            <ConfirmDeleteChecklistItemDialog
              folderId={folderId}
              checklistItem={checklistItem}
              onClose={() => setAction(undefined)}
            />
          </Modal>
        ))
        .otherwise(() => null)}
    </>
  )
}

export type STFormationChecklistPDFProps = {
  entityName: string
  type: string
  year: number
  documents: STDocument[]
  page: PaginatedPage<STChecklistItem>
  missingReasonsById: Record<string, STMissingReason>
}

export function STFormationChecklistPDF(props: STFormationChecklistPDFProps) {
  const { entityName, page } = props
  return (
    <Column mainAxisSize="min">
      {page.isFirst && (
        <Row>
          <Expand flex={4}>
            <Box width={Infinity} height={24} borderColor="#ffffff" borderWidth={1}>
              <Text fontSize={24} fontStyle="normal" overflow="ellipsis">
                {`${entityName}'s Checklist`}
              </Text>
            </Box>
          </Expand>
          <Expand>
            <Box width={Infinity} height={32} borderColor="#ffffff" borderWidth={1} hAlign="end">
              <Column align="end">
                <Text fontSize={10} fontStyle="normal">
                  {`RETURN TYPE: ${props.type}`}
                </Text>
                <Space h={8} />
                <Text fontSize={10} fontStyle="normal">
                  {`TAX YEAR: ${props.year}`}
                </Text>
              </Column>
            </Box>
          </Expand>
        </Row>
      )}

      {page.isFirst && <Space h={40} />}

      {page.items.map((checklistItem) => {
        const document = props.documents.find(
          (doc) => doc.id == checklistItem.reference?.documentId
        )

        const incompleteReason = checklistItem.incompleteReason
          ? props.missingReasonsById[checklistItem.incompleteReason]
          : undefined

        return (
          <Box
            key={checklistItem.id}
            width={Infinity}
            height={20}
            borderColor={FORM_BORDER_GREY}
            borderWidth={0.5}
            padding={Pad.all(6)}
          >
            <Row mainAxisSize="max" gap={12}>
              <Box
                height={20}
                width={20}
                hAlign="center"
                vAlign="start"
                padding={Pad.only({ bottom: 6 })}
              >
                <Checkbox name={checklistItem.id} value={checklistItem.status == 'complete'} />
              </Box>
              <Expand flex={3}>
                <Column mainAxisSize="min">
                  <Box height={20} width={Infinity}>
                    <Text fontSize={10} fontStyle="normal">
                      {checklistItem.name}
                    </Text>
                  </Box>
                </Column>
              </Expand>

              <Expand flex={4}>
                <Column mainAxisSize="min">
                  <Box height={20} width={Infinity}>
                    <Text fontSize={10} fontStyle="normal" overflow="ellipsis">
                      {checklistItem.note}
                    </Text>
                  </Box>
                </Column>
              </Expand>

              <Expand flex={6}>
                <Column mainAxisSize="min">
                  <Box height={20} width={Infinity}>
                    <STChecklistViewerItemLink
                      item={checklistItem}
                      document={document}
                      incompleteReason={incompleteReason}
                    />
                  </Box>
                </Column>
              </Expand>
            </Row>
          </Box>
        )
      })}
    </Column>
  )
}

function STChecklistViewerItemLink({
  item,
  document,
  incompleteReason
}: {
  item: STChecklistItem
  document: STDocument | undefined
  incompleteReason: STMissingReason | undefined
}) {
  if (document) {
    return (
      <Text fontSize={10} fontStyle="normal" color="#354dcc" overflow="ellipsis">
        {document.name}
      </Text>
    )
  } else if (incompleteReason) {
    return (
      <Text fontSize={10} fontStyle="normal" color="#848796">
        {incompleteReason.name}
      </Text>
    )
  } else if (item.constraint.type == 'document_type') {
    return (
      <Text fontSize={10} fontStyle="normal" color="#848796">
        Not uploaded
      </Text>
    )
  } else if (item.constraint.type == 'category') {
    return (
      <Text fontSize={10} fontStyle="normal" color="#848796">
        Not filled in
      </Text>
    )
  } else {
    return null
  }
}

type ChecklistState =
  | { status: 'idle' }
  | { status: 'addItem' }
  | { status: 'editItem'; checklistItemId: string }

function STFormationChecklistHTML({
  entityName,
  page,
  documents,
  missingReasonsById,
  year,
  type,
  onChecklistItemAction,
  dragHandlers,
  dragState
}: {
  entityName: string
  type: string
  year: number
  page: PaginatedPage<STChecklistItem>
  documents: STDocument[]
  missingReasonsById: Record<string, STMissingReason>
  onChecklistItemAction: (action: ChecklistItemAction) => void
  dragHandlers: (item: STChecklistItem) => DragHandlersProps
  dragState: DragState<STChecklistItem>
}) {
  const [state, setState] = useState<ChecklistState>({ status: 'idle' })

  return (
    <div className="flex flex-col items-stretch">
      {page.isFirst && (
        <div className="flex flex-row items-center">
          <h1 className="mb-8 w-2/3 text-3xl">{`${entityName}'s Checklist`}</h1>

          <div className="flex w-1/3 flex-col items-end">
            <div className="flex flex-row justify-end gap-2">
              <div className="w-40 text-right text-xs uppercase text-gray-600">Return type</div>
              <div className="w-8 text-xs uppercase text-gray-900">{type}</div>
            </div>

            <div className="flex flex-row justify-end gap-2">
              <div className="w-40 text-right text-xs uppercase text-gray-600">Tax year</div>
              <div className="w-8 text-xs uppercase text-gray-900">{year}</div>
            </div>

            <Button
              variant="default"
              className="mt-2"
              onClick={() => onChecklistItemAction({ type: 'requestItems' })}
            >
              Request documents
            </Button>
          </div>
        </div>
      )}

      {page.items.length == 0 ? (
        <ChecklistItemEmptyStateCTA entityName={entityName} />
      ) : (
        <Checklist.Table>
          {page.items.map((item) => {
            const document = item.reference?.documentId
              ? documents.find((doc) => doc.id == item.reference?.documentId)
              : undefined
            const incompleteReason = item.incompleteReason
              ? missingReasonsById[item.incompleteReason]
              : undefined

            if (state.status == 'editItem' && state.checklistItemId == item.id) {
              return (
                <EditChecklistItem
                  key={item.id}
                  checklistItem={item}
                  onClose={() => setState({ status: 'idle' })}
                />
              )
            }

            return (
              <STFolderChecklistItem
                draggable={false}
                key={item.id}
                item={item}
                document={document}
                incompleteReason={incompleteReason}
                onChecklistItemAction={(action) => {
                  switch (action.type) {
                    case 'edit':
                      setState({ status: 'editItem', checklistItemId: item.id })
                      break
                    default:
                      onChecklistItemAction(action)
                  }
                }}
                {...dragHandlers(item)}
                dragState={dragState}
              />
            )
          })}

          {page.isLast && (
            <Checklist.Footer>
              {/* Row should get rebuilt when items are added/removed */}
              <AddChecklistItemRow
                key={page.items.length}
                isActive={state.status == 'addItem'}
                onSetActive={(active) =>
                  setState(active ? { status: 'addItem' } : { status: 'idle' })
                }
              />
            </Checklist.Footer>
          )}
        </Checklist.Table>
      )}
    </div>
  )
}

function ChecklistItemEmptyStateCTA({ entityName }: { entityName: string }) {
  const [isActive, setActive] = useState(false)

  return (
    <div className="flex flex-col">
      {!isActive && (
        <CTACard
          icon={<ChecklistVisual className="h-16 w-16" />}
          message={`Create a checklist item for ${entityName}`}
          button={
            <Button className="mt-4" variant="primary" onClick={() => setActive(true)}>
              Add checklist item
            </Button>
          }
        />
      )}
      {isActive && <AddChecklistItemRow isActive={isActive} onSetActive={setActive} />}
    </div>
  )
}
function AddChecklistItemRow({
  isActive,
  onSetActive
}: {
  isActive: boolean
  onSetActive: (active: boolean) => void
}) {
  const { sdk } = useAppDeps()

  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)
  const documentTypes = useProcessState(stFolderModule, selDocumentTypeOptions)

  const [documentTypeId, setDocumentTypeId] = useState<string | undefined>(undefined)
  const [note, setNote] = useState<string>('')

  const selectedDocumentType = documentTypes.find((dt) => dt.id == documentTypeId)
  const [isCreating, setCreating] = useState(false)

  if (isActive) {
    return (
      <div className="flex w-full items-center gap-3 whitespace-nowrap bg-stone-100 px-8 py-2">
        <Select
          className="w-60"
          placeholder="Document type"
          options={documentTypes}
          buildValue={(documenType) => documenType.id}
          buildLabel={(documenType) => documenType.name}
          value={documentTypeId}
          onChange={setDocumentTypeId}
        />

        <TextInput
          placeholder="Note"
          className="flex-grow"
          autoFocus={true}
          value={note}
          onChange={setNote}
        />

        <Button
          variant="primary"
          disabled={selectedDocumentType == undefined && !isCreating}
          onClick={async () => {
            setCreating(true)
            await sdk.send({
              type: 'folders/createChecklistItem',
              documentTypeId: selectedDocumentType!.id,
              name: selectedDocumentType!.name,
              note,
              folderId: folderId
            })
            onSetActive(false)
          }}
        >
          {isCreating ? 'Saving' : 'Save'}
        </Button>
        <Button variant="subtle" onClick={() => onSetActive(false)}>
          Cancel
        </Button>
      </div>
    )
  } else {
    return (
      <div className="flex h-11 items-center">
        <Button
          variant="subtle"
          leadingIcon={<PlusIcon className="h-4 w-4 text-gray-900" />}
          onClick={() => onSetActive(true)}
        >
          Add item
        </Button>
      </div>
    )
  }
}

function EditChecklistItem({
  checklistItem,
  onClose
}: {
  checklistItem: STChecklistItem
  onClose: () => void
}) {
  const { sdk } = useAppDeps()

  const platform = useProcess(platformModule)
  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)
  const [note, setNote] = useState(checklistItem.note)

  const [isSaving, setIsSaving] = useState(false)

  return (
    <div className="flex w-full items-center gap-3 whitespace-nowrap bg-stone-100 px-8 py-2">
      <Select
        className="w-60"
        placeholder={checklistItem.name}
        options={[]}
        buildValue={() => checklistItem.name}
        buildLabel={() => checklistItem.name}
        value={undefined}
      />

      <TextInput
        placeholder="Note"
        className="flex-grow"
        autoFocus={true}
        value={note}
        onChange={setNote}
      />

      <Button
        variant="primary"
        disabled={note == checklistItem.note}
        onClick={async () => {
          setIsSaving(true)
          await sdk.send({
            type: 'folders/editChecklistItem',
            folderId: folderId,
            checklistItemId: checklistItem.id,
            note: note
          })
          platform.send({
            type: 'showSnackbar',
            message: `Saved note for ${checklistItem.name}`
          })
          setIsSaving(false)
          onClose()
        }}
      >
        {isSaving ? 'Saving' : 'Save'}
      </Button>
      <Button variant="subtle" onClick={onClose}>
        Cancel
      </Button>
    </div>
  )
}

type STFolderChecklistItemProps = {
  item: STChecklistItem
  document: STDocument | undefined
  incompleteReason: STMissingReason | undefined
  onChecklistItemAction: (action: ChecklistItemAction) => void
  dragState: DragState<STChecklistItem>
} & Partial<DragHandlersProps>

function STFolderChecklistItem({
  item,
  document,
  incompleteReason,
  onChecklistItemAction,
  dragState,
  draggable,
  onDragStart,
  onDragOver,
  onDrop,
  onDragLeave
}: STFolderChecklistItemProps) {
  const dragStatus = (() => {
    if (dragState.status == 'hovering' && dragState.target == item) {
      return dragState.relation
    } else if (
      (dragState.status == 'hovering' || dragState.status == 'moving') &&
      dragState.item == item
    ) {
      return 'dragging'
    }
    return undefined
  })()

  return (
    <Checklist.Row
      key={item.id}
      checked={item.status == 'complete'}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
      dragStatus={dragStatus}
    >
      <Checklist.DragHandleCell enabled={draggable} />
      <Checklist.CheckboxCell
        checked={item.status == 'complete'}
        onChange={() => onChecklistItemAction?.({ type: 'manageCompletion', checklistItem: item })}
      />
      <Checklist.MainCell>{item.name}</Checklist.MainCell>
      <Checklist.NoteCell>{item.note}</Checklist.NoteCell>
      <STChecklistItemLink
        item={item}
        document={document}
        incompleteReason={incompleteReason}
        onChecklistItemAction={onChecklistItemAction}
      />
      <Checklist.ActionGroup>
        <Checklist.Button
          tip="Edit checklist item note"
          onClick={() => onChecklistItemAction({ type: 'edit', checklistItem: item })}
        >
          <PencilSimpleIcon />
        </Checklist.Button>
        <Checklist.Button
          tip="Delete checklist item"
          onClick={() =>
            onChecklistItemAction({
              type: 'delete',
              checklistItem: item
            })
          }
        >
          <TrashIcon />
        </Checklist.Button>
      </Checklist.ActionGroup>
    </Checklist.Row>
  )
}

function STChecklistItemLink({
  item,
  document,
  incompleteReason,
  onChecklistItemAction
}: {
  item: STChecklistItem
  document: STDocument | undefined
  incompleteReason: STMissingReason | undefined
  onChecklistItemAction: (action: ChecklistItemAction) => void
}) {
  if (document) {
    return (
      <Checklist.LinkCell>
        <a
          className="flex cursor-pointer items-center gap-2 truncate text-blue-500"
          onClick={() =>
            onChecklistItemAction({
              type: 'selectDocument',
              checklistItem: item,
              document: document
            })
          }
          // onClick={() => onSelectDocument(document)}
        >
          <LinkIcon className="h-4 w-4 min-w-4" />
          {document.name}
        </a>
      </Checklist.LinkCell>
    )
  } else if (incompleteReason) {
    return (
      <Checklist.LinkCell>
        <IncompleteReasonTag>{incompleteReason.name}</IncompleteReasonTag>
      </Checklist.LinkCell>
    )
  } else if (item.constraint.type == 'document_type') {
    return <Checklist.LinkCell>Not uploaded</Checklist.LinkCell>
  } else if (item.constraint.type == 'category') {
    return <Checklist.LinkCell>Not filled in</Checklist.LinkCell>
  } else {
    return <Checklist.LinkCell></Checklist.LinkCell>
  }
}

function IncompleteReasonTag({ children }: { children: ReactNode }) {
  return <div className="rounded-sm bg-blue-200 px-1 text-sm text-blue-700">{children}</div>
}

function ConfirmDeleteChecklistItemDialog({
  folderId,
  checklistItem,
  onClose
}: {
  folderId: string
  checklistItem: STChecklistItem
  onClose: () => void
}) {
  const platform = useProcess(platformModule)
  const { sdk } = useAppDeps()

  return (
    <Dialog
      title={'Confirm delete'}
      className="w-96"
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              await sdk.send({
                type: 'folders/deleteChecklistItem',
                folderId: folderId,
                checklistItemId: checklistItem.id
              })
              platform.send({
                type: 'showSnackbar',
                message: `Deleted checklist item ${formatChecklistItem(checklistItem)}`
              })
              onClose()
            }}
          >
            Delete
          </Button>
        </DialogButtons>
      }
    >
      <div className="flex flex-col gap-2">
        Are you sure you want to delete the checklist item {formatChecklistItem(checklistItem)}?
      </div>
    </Dialog>
  )
}
