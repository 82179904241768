import { ComponentConfig, OptionConfig } from './src/ui-config'

export * from './src/ui-config'

export type SectionID = string

export type ComponentConfigType = ComponentConfig['__typename'] & string

export const NONE_OPTION: OptionConfig = {
  key: 'none',
  label: 'None of the above'
}

export const UNCATEGORIZED_DOCUMENT_TYPE_ID = 'uncategorized'
