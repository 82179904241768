import {
  ComponentConfigType,
  FormCategoryConfig,
  FormConfig,
  FormPageConfig,
  FormRowConfig,
  FormSectionConfig
} from '@st/folder/ui-config'
import {
  Bookmark,
  Box,
  DocumentRenderModeProvider,
  Expand,
  FORM_BORDER_GREY,
  PDFFormDocument,
  PDFFormPage,
  PageHeading,
  Section,
  SectionRow,
  Space,
  useReadWriteContext
} from '@st/pdf'
import { DrawOp } from '@st/pdf/src/document/draw'
import { renderToPDFStruct } from '@st/pdf/src/document/render-to-pdf-struct'
import { JSONValue, isNotEmpty } from '@st/util/json-value'
import { ComponentProps, Fragment, useState } from 'react'
import { FormationComponent } from './formation-component'

/**
 * Height of a multiline text field for PDF documents
 */
const FORMATION_MULTILINE_TEXT_HEIGHT = 370

type Props = { config: FormConfig }
export function FormationDocument({ config }: Props) {
  const pages = config.pages.map((p, index) => {
    if (index == 0) {
      return { ...p, title: config.name }
    }
    return p
  })

  return (
    <PDFFormDocument zoom={1.5}>
      {pages.map((p, index) => (
        <DataIndicator key={index} config={p} />
      ))}
    </PDFFormDocument>
  )
}

function DataIndicator(props: ComponentProps<typeof FormationDocumentPage>) {
  const { useReadInputs } = useReadWriteContext()

  const [fieldsOnPage] = useState(() => {
    const struct = renderToPDFStruct(
      <DocumentRenderModeProvider value="pdf">
        <PDFFormDocument>
          {/* We render this once without hasData to extract what keys are on the page */}
          <FormationDocumentPage {...props} />
        </PDFFormDocument>
      </DocumentRenderModeProvider>,
      {
        textSize: () => {
          return { width: 0, height: 0 }
        }
      }
    )
    return relevantFields(struct.pages[0].drawOp)
  })

  const hasData = useReadInputs((inputs) =>
    fieldsOnPage.some((field) => {
      return fieldNotEmpty(inputs[field.name], field)
    })
  )

  // Now we render the actual component and pass in whether there is data on the page
  return <FormationDocumentPage {...props} hasData={hasData} />
}

function fieldNotEmpty(value: JSONValue, field: Field) {
  // There's one exception for a field not being empty
  // checkboxes that are marked as unchecked have a false value
  // we consider this to be empty
  if (field.type == 'checkbox' && value === false) {
    return false
  }
  return isNotEmpty(value)
}

type Field = {
  type: 'checkbox' | 'radio' | 'text_input' | 'hidden_input'
  name: string
}
function relevantFields(op: DrawOp): Field[] {
  switch (op.type) {
    case 'checkbox':
    case 'radio':
    case 'text_input':
    case 'hidden_input':
      return [{ type: op.type, name: op.name }]
    case 'group':
      return op.ops.flatMap(relevantFields)
    default:
      return []
  }
}

function FormationDocumentPage({ config, hasData }: { config: FormPageConfig; hasData?: boolean }) {
  const sections = config.sections

  return (
    <PDFFormPage width={650} height={1000}>
      {config.title ? (
        <PageHeading hasData={!config.title!.includes('Organizer') && hasData}>
          {config.title!}
        </PageHeading>
      ) : null}
      {config.title ? (
        <Bookmark
          path={config.id}
          title={resolveTitle(config.title, hasData)}
          fontWeight={hasData ? 'bold' : 'normal'}
        />
      ) : null}

      {config.title ? <Space h={20} /> : null}

      {sections.map((s, index) => (
        <Fragment key={index}>
          <FormationPageSection config={s} />
          <Space h={20} />
        </Fragment>
      ))}
    </PDFFormPage>
  )

  function resolveTitle(title: string, hasData: boolean | undefined) {
    return title.replace(/^.*Organizer.*$/, 'Basic Info') + (hasData ? '*' : '')
  }
}

function FormationPageSection({ config }: { config: FormSectionConfig }) {
  const rows = config.rows ?? []
  const categoryRows = categoryToRows(config.categories ?? [])

  return (
    <Section title={config.title ?? undefined}>
      {categoryRows.map((row, index) => {
        return <OrganizerRow key={index} config={row} />
      })}
      {rows.map((row, index) => {
        return <OrganizerRow key={index} config={row} />
      })}
    </Section>
  )
}

function categoryToRows(categories: FormCategoryConfig[]): FormRowConfig[] {
  const rows: FormRowConfig[] = []

  for (var c of categories) {
    if (c.headerRowComponents.length > 0) {
      rows.push({
        id: c.name,
        components: c.headerRowComponents
      })
    }
    if (c.rows) rows.push(...c.rows)
  }

  return rows
}

const MULTI_ROW_COMPONENTS_TYPES: ComponentConfigType[] = [
  'LabeledGridTableConfig',
  'GridTableConfig',
  'StandardTableConfig',
  'AddressInputConfig',
  'ShareholderInputConfig',
  'ChecklistConfig'
]

function OrganizerRow({ config }: { config: FormRowConfig }) {
  const components = config.components

  if (components.length == 1) {
    const component = components[0]
    if (MULTI_ROW_COMPONENTS_TYPES.includes(component.__typename!)) {
      return <FormationComponent config={component} />
    } else if (component.__typename == 'TextInputConfig' && component.isMultiline) {
      return (
        <Box
          width={Infinity}
          height={FORMATION_MULTILINE_TEXT_HEIGHT}
          borderWidth={1}
          borderColor={FORM_BORDER_GREY}
        >
          <FormationComponent config={component} />
        </Box>
      )
    }
  }

  return (
    <SectionRow
      bullet={toBullet(config.bulletNumber)}
      indent={config.indent ?? undefined}
      isZeroWidth={(index) => components[index]?.__typename == 'NoteWidgetConfig'}
    >
      {components.map((c, index) =>
        c.__typename == 'NoteWidgetConfig' ? (
          <FormationComponent key={index} config={c} />
        ) : (
          <Expand key={index}>
            <FormationComponent config={c} />
          </Expand>
        )
      )}
    </SectionRow>
  )

  function toBullet(n: number | undefined | null) {
    if (!n) return undefined
    return `${n}.`
  }
}
